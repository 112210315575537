// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@tailwind base;
@tailwind components;
@tailwind utilities;
// Include the common styles for Angular Material. We include this here so that you only 
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$newLideartSSR-primary: mat.define-palette(mat.$indigo-palette);
$newLideartSSR-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$newLideartSSR-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$newLideartSSR-theme: mat.define-light-theme((
  color: (
    primary: $newLideartSSR-primary,
    accent: $newLideartSSR-accent,
    warn: $newLideartSSR-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($newLideartSSR-theme);


/* You can add global styles to this file, and also import other style files */
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
/*@import "~swiper/scss";
@import "~swiper/scss/pagination";*/

.ion-color-danger{
    position: absolute;
}

.ion-color-success{
    position: absolute;
}

.paypal-checkout-sandbox-iframe {
    border: 0;    
}

html.plt-ios .paypal-checkout-sandbox-iframe {
    top: env(safe-area-inset-top) !important;
}

.cont-back-btn{
    width: 100%;
    height: auto;
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0.5em;
    padding-top: 0.5em;
  
    .btn-back{
      background-color: #00c0f6 !important;
      padding: 0.3em 1.5em;
      font-size: 1.2em;
      border-radius: 2em;
    }
}
  
@media( max-width : 992px ){
    .cont-back-btn{
        display: flex;
    }
}

@media(min-width: 992px){
    body{
        background-color: #fff;
        height: 100%;
    }

    html{
        height: 100%;
    }

    .desaparece-desk{
        display: none;
    }
}

*{
    position: relative;
    contain: unset;
    overflow: unset;
}

body{
    position: relative!important;;
    overflow: unset!important;;
    background-color: #fff!important;;
    padding-top: env(safe-area-inset-top)!important;;
}

body,html{
    height: 100%;
}

.quita-ionic-content{
    background-color: #fff;
}

.ion-page{
    position: relative;
    contain: unset;
    overflow: unset;
}

.loginAlertComp{
    --width: 100%;
    --height: auto;
    --backdrop-opacity: .7;


    .alert-wrapper {
        background: #14A8CD5c;
        backdrop-filter: blur(2px);
    }

    .alert-title{
        color: rgb(70, 255, 255);
        letter-spacing: .2rem;
        font-weight: bolder;
    }

    /*.alert-sub-title{
        color: blue;
    }*/

    .alert-message{
        color: #fff;
        font-size: .9rem;
        font-weight: bolder;
        letter-spacing: .1rem;
    }

    .alert-button{
        font-size: 1.2rem;
        color: rgb(70, 255, 255);
        font-weight: bolder;
        letter-spacing: .1rem;
    }
}

.modalRecuperarCss{
    position: absolute;

    --background: #14a8cd29;

    --width: 100%;
    --height: 100%;

    &::part(content){
        backdrop-filter: blur(7px);
    }

    ion-content{
        --background: transparent!important;
    }
    
}

.modalRegistroCss{
    position: absolute;

    --background: #14A8CD5c;

    --width: 100%;
    --height: 100%;

    &::part(content){
        backdrop-filter: blur(7px);
    }

    ion-content{
        --background: transparent!important;
    }
    
}

::-webkit-scrollbar {
    width: .4em;
    background-color: transparent;
    height: .7em;
}
  
::-webkit-scrollbar-thumb {
    background-color: #0ebde5;
    //border-radius: 1vh;
    width: 0.25em;
} 


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
